import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";
import { AppText, AppTitle, AppButton, AppLogo } from "../../ui";
import { logo, AppleStoreIcon, Stars } from "../../../content/assets";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { fbq } from "../../../services/pixel";

export const ContentLanding = ({ book }) => {
  const handleSubmit = () => {
    fbq("trackCustom", "start_questions");
    navigate("/onboarding/questions");
  };

  // const getImageURL = (fileName: string) => `https://firebasestorage.googleapis.com/v0/b/know-d8bc0.appspot.com/o/${fileName}?alt=media&token=8c8db0fc-6e87-4451-bb44-141182847cdb`;
  const getImageURL = (fileName: string) => {
    const fileNameArr = fileName.split(".");
    const newFileName = `${fileNameArr[0]}_400x400.${fileNameArr[1]}`;
    return `https://firebasestorage.googleapis.com/v0/b/know-d8bc0.appspot.com/o/images%2Fresized%2F${newFileName}?alt=media&token=8e3d0150-0763-49e3-bd8b-445c66c9a4c0`;
  };

  return (
    <Container>
      <Content>
        <LogoContainer>
          <AppLogo white size="medium" />
        </LogoContainer>
        <AppTitle
          margin="0 0 1rem 0"
          maxWidth={500}
          textAlign="center"
          fontSize={36}
          color="#fefefe"
        >
          {book.title}
        </AppTitle>
        <Subtitle>{book.author}</Subtitle>
        <ImgGradient>
          <Img noBorder src={getImageURL(book.main_image)} alt={`${book.title} cover`} />
        </ImgGradient>
        <TopBorderContainer>
          <AppText color="#fefefe" textAlign="center">
            {book.description}
          </AppText>
          <SignupContainer>
            <AppText
              fontSize={24}
              color="#000"
              textAlign="center"
              style={{ marginBottom: "0.5rem" }}
            >
              Sign up to start a{" "}
              <Blue>
                <b>7-day free trial.</b>
              </Blue>
            </AppText>
            <DesktopText fontSize={16} textAlign="center">
              then <b>$99.99 per year</b> ($8.33/month)
            </DesktopText>
            <MobileText fontSize={16} textAlign="center">
              You’ll get access to this visual guide & more!
            </MobileText>
            <DesktopButton onClick={handleSubmit}>Get Started</DesktopButton>
          </SignupContainer>
          <MobileText fontSize={16} color="#fefefe" textAlign="center">
            then <b>$99.99 per year</b> ($8.33/month)
          </MobileText>
          <MobileButton onClick={handleSubmit}>Get Started</MobileButton>
        </TopBorderContainer>
        {!!book.chapter_list_image_1 &&
        !!book.chapter_list_image_2 &&
        !!book.chapter_list_image_3 &&
        !!book.chapter_list_image_4 ? (
          <TopBorderContainer>
            <AppText
              color="#fefefe"
              textAlign="center"
              fontSize={24}
              mobileFontSize={24}
              style={{ margin: "3rem 0" }}
            >
              <b>See What&apos;s Inside</b>
            </AppText>
            <ImageGrid>
              <Img
                src={getImageURL(book.chapter_list_image_1)}
                alt={`${book.title} first chapter`}
                loading="lazy"
              />
              <Img
                src={getImageURL(book.chapter_list_image_2)}
                alt={`${book.title} second chapter`}
                loading="lazy"
              />
              <Img
                src={getImageURL(book.chapter_list_image_3)}
                alt={`${book.title} third chapter`}
                loading="lazy"
              />
              <Img
                src={getImageURL(book.chapter_list_image_4)}
                alt={`${book.title} fourth chapter`}
                loading="lazy"
              />
            </ImageGrid>
            <AppButton onClick={handleSubmit}>Get Started</AppButton>
          </TopBorderContainer>
        ) : null}
      </Content>
      <FooterContainer>
        <FlexCol>
          <AppLogo size="medium" style={{ marginBottom: 24 }} />
          <AppTitle fontSize={36} maxWidth={650} textAlign="center">
            The world&apos;s most important knowledge, <Blue>visualized</Blue>.
          </AppTitle>
          <Ratings>
            <FlexRow>
              <SizedImg src={AppleStoreIcon} alt="apple store icon" width={24} height={24} />
              <span>7.4K Ratings</span>
            </FlexRow>
            <SizedImg src={Stars} alt="five stars rating" width={132} height={22} />
          </Ratings>
        </FlexCol>
        <FlexCol>
          <DesktopText fontSize={24} style={{ marginBottom: "0.5rem" }}>
            <b>Free 7-Day Trial</b>
          </DesktopText>
          <DesktopText fontSize={16}>
            then <b>$99.99 per year</b> ($8.33/month)
          </DesktopText>
          <MobileText textAlign="center">7-day free trial, then</MobileText>
          <MobileText textAlign="center">
            <b>$99.99 per year</b> ($8.33/month)
          </MobileText>
          <AppButton margin="1rem 0 0 0" onClick={handleSubmit}>
            Get Started
          </AppButton>
        </FlexCol>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 0;
  padding: 0;
  background-color: #292032;

  * {
    font-family: ${theme.PRIMARY_FONT};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5.6rem 0;
  max-width: 600px;
  background: #292032;
  color: #fefefe;

  & > * {
    margin-bottom: 2rem;
  }

  @media ${mobileSize} {
    max-width: 340px;
    padding: 10rem 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 5.6rem;
  left: 12rem;
  height: 2.5rem;

  @media ${mobileSize} {
    position: static;
  }
`;

const Subtitle = styled.h2`
  margin: 0;
  margin-bottom: 2rem;

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
`;

const ImgGradient = styled.div`
  position: relative;
  width: 380px;
  height: 440px;
  border-radius: 16px;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(
      to right,
      hsla(270, 22%, 16%, 0.75) 0%,
      hsla(270, 22%, 16%, 0) 20%,
      hsla(270, 22%, 16%, 0) 80%,
      hsla(270, 22%, 16%, 0.75) 100%
    );
  }

  @media ${mobileSize} {
    width: 250px;
    height: 315px;
  }
`;

interface ImgProps {
  noBorder?: boolean;
}

const Img = styled.img<ImgProps>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: ${({ noBorder }) => (noBorder ? "none" : "2px solid #fefefe")};
  object-fit: cover;
`;

const TopBorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 2px solid #edeeef;
  padding-top: 2rem;
  color: #fefefe;
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  padding: 2rem 4rem;
  border-radius: 16px;
  width: 80%;
  background-color: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    width: 100%;
    padding: 2rem;
  }
`;

const DesktopText = styled(AppText as any)`
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileText = styled(AppText as any)`
  color: ${theme.PRIMARY_COLOR};
  display: none;
  margin: 0;
  @media ${mobileSize} {
    display: block;
  }
`;

const DesktopButton = styled(AppButton as any)`
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileButton = styled(AppButton as any)`
  display: none;
  margin-top: 1rem;
  @media ${mobileSize} {
    display: block;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 180px);
  grid-template-rows: repeat(2, 180px);
  grid-gap: 24px;
  margin: 2rem 0 6rem 0;

  @media ${mobileSize} {
    grid-template-columns: repeat(2, 168px);
    grid-template-rows: repeat(2, 168px);
    grid-gap: 8px;
  }
`;

const FooterContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 6rem;
  background: #fefefe;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  @media ${mobileSize} {
    margin-bottom: 1rem;
  }
`;

const Logo = styled.img`
  margin-bottom: 5rem;
  width: 8rem;
  height: 2.2rem;

  @media ${mobileSize} {
    margin-bottom: 9rem;
  }
`;

const Blue = styled.span`
  color: ${theme.PRIMARY_COLOR};
`;

interface SizedImgProps {
  width: number;
  height: number;
}

const SizedImg = styled.img<SizedImgProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Ratings = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 9rem;
  font-size: 1.8rem;
  * {
    margin-right: 1rem;
  }

  @media ${mobileSize} {
    flex-direction: column;
    * :last-child {
      margin-right: 0;
    }
  }
`;
