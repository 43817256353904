import * as React from "react";
import { useEffect } from "react";
import { ContentLanding } from "../components/PageSegments/onboarding/ContentLanding";
import { dynamicLandingViewed } from "../services/mixpanel/mixpanel";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { BasicLayout } from "../layouts/BasicLayout";

export default ({ pageContext, location }) => {
  const { book } = pageContext;
  const { search } = location;
  const { setOnboardingLandingPage } = useOnboardingContext();

  useEffect(() => {
    setOnboardingLandingPage(`${book.title} Landing Page`);
    dynamicLandingViewed(book.title);
    if (search) {
      const urlParams = new URLSearchParams(search);
      // new cookie will be expired in 1 year
      const utm_date = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

      for (const key of urlParams.keys()) {
        // set those parameter as cookies
        const value = urlParams.get(key);
        document.cookie = `${key}=${value}; expires=${utm_date.toUTCString()}`;
      }
    }
  }, [search, setOnboardingLandingPage, book.title]);

  return (
    <BasicLayout>
      <ContentLanding book={book} />
    </BasicLayout>
  );
};
